import { createStore } from 'vuex'

export default createStore({
  state: {
    URL_CLIENT : 'https://gali-integral.konaweselatankab.go.id',
    // URL_CLIENT : 'http://localhost:8080',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
