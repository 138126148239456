<template>
  <div>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->

<br>
    <div>
      
      <img class="img_logo" src="img/konsel.png" alt="">
    </div>


    <div class="text-home">
      <div class="h_title_pemda">PEMERINTAH KAB. KONAWE SELATAN</div>
      <div class="h_title_dinas">BADAN KEPEGAWAIAN DAN PENGEMBANGAN SUMBER DAYA MANUSIA</div>

      <!-- <div>GALI INTEGRAL</div> -->
    </div>





    <br>


    <div class="h_judul textx">Portal Gali Integral</div>
    <div class="h_sub_judul">Cegah Pungli dengan Integrasi Layanan</div>

    <div style="padding-top: 2%;"></div>



    <div class="contentx">
      <div class="rowx">

        <a :href="$store.state.URL_CLIENT+'/app/pensiun/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/pensiun.png" alt="">
          <span class="fonts_icon">Pensiun</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/cuti/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/cuti.png" alt="">
          <span class="fonts_icon">Cuti</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/hukdis/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/hukdis1.png" alt="">
          <span class="fonts_icon">Hukdis</span>
        </a>
        
        <a :href="$store.state.URL_CLIENT+'/app/absensi/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/absen.png" alt="">
          <span class="fonts_icon">Absensi</span>
        </a>
        
        <a :href="$store.state.URL_CLIENT+'/app/rizi/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/cerai.png" alt="">
          <span class="fonts_icon">Perceraian</span>
        </a>
        
        <a :href="$store.state.URL_CLIENT+'/app/kgb/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/kgb.png" alt="">
          <span class="fonts_icon">KGB</span>
        </a>
        
        <a :href="$store.state.URL_CLIENT+'/app/kinerja/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/kinerja1.png" alt="">
          <span class="fonts_icon">Kinerja</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/aduan/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/chat.png" alt="">
          <span class="fonts_icon">Aduan</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/pangkat/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/pangkat.png" alt="">
          <span class="fonts_icon">Pangkat</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/mutasi/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/mutasi1.png" alt="">
          <span class="fonts_icon">Mutasi</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/jabfung/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/jabfung.png" alt="">
          <span class="fonts_icon">Jabfung</span>
        </a>
        <a :href="$store.state.URL_CLIENT+'/app/diklat/#/'" class="colx neumorp">
          <!-- <i class="fa-light fa-fire"></i> -->
          <img class="img_icon" src="img/tubel1.png" alt="">
          <span class="fonts_icon">Pendidikan</span>
        </a>
        
        


        
      </div>
    </div>







   







  </div>
</template>

<script>
export default {
  data() {
    return {
      key: 'value'
    }
  },

  methods: {
    routex(routex) {
      this.$router.push(routex)
      // location.reload();
    }
  },
}
</script>

<style lang="scss" scoped>

  

</style>